<template>
  <div>
      <b-card class="complete-identity-card mb-5">
          <div class="row">
              <div class="col-lg-6 text-center text-lg-left my-auto">
                <div class="complete-identity">
                    <p class="my-auto">Lengkapi data diri Anda untuk mulai berinvestasi.</p>
                </div>
              </div>
              <div class="col-lg-6 text-center text-lg-right py-2">
          <b-button to="/pembukaan-akun" class="btn-buy px-4 py-2">
            Lengkapi Data Sekarang
          </b-button>
              </div>
          </div>
        </b-card>
  </div>
</template>

<script>
export default {
  name: "PortfolioSidebar",
};
</script>

<style lang="scss" scooped>
.complete-identity-card {
  background: #FDEEEC;
border: 1px solid #F4887C;
box-sizing: border-box;
border-radius: 6px;
}

.complete-identity {
  p {
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #380A05;
  }
}
.btn-buy {
font-style: normal;
font-weight: 700;
font-size: 14px;
}
</style>