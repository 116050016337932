<template>
  <div>
    <template v-if="check">
      <nav class="navbar navbar-dark bg-white navbar-expand fixed-bottom d-md-none d-lg-none d-xl-none p-0">
          <ul class="navbar-nav nav-justified w-100 py-1">
              <li class="nav-item" >
                  <b-link class="nav-link text-center" to="/">
                    <img
                      src="@/assets/img/icons/navigation/home-ic.svg"
                      alt="Home Icon"
                      class="w-20"
                    />
                      <span class="d-block">Home</span>
                  </b-link>
              </li>
              <li class="nav-item">
                  <b-link to="/portfolio" class="nav-link text-center">
                    <img
                      src="@/assets/img/icons/navigation/portfolio-ic.svg"
                      alt="Portfolio Icon"
                      class="w-20"
                    />
                      <span class="d-block">Portfolio</span>
                  </b-link>
              </li>
              <li class="nav-item">
                  <b-link to="/orders" class="nav-link text-center">
                    <img
                      src="@/assets/img/icons/navigation/orders-ic.svg"
                      alt="Transaction Icon"
                      class="w-20"
                    />
                      <span class="d-block">Aktivitas</span>
                  </b-link>
              </li>
              <li class="nav-item dropup">
                  <b-link to="/profile" class="nav-link text-center" role="button" id="dropdownMenuProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                    <img
                      src="@/assets/img/icons/navigation/profile-active-ic.svg"
                      alt="Profile Icon"
                      class="w-20"
                    />
                      <span class="nav-active d-block">Profile</span>
                  </b-link>
              </li>
          </ul>
      </nav>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HomeHeader",
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      user: "auth/user",
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      signout: "auth/signout",
    }),
    async logout() {
      await this.signout();
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
span {
font-style: normal;
font-weight: 700;
font-size: 9px;
text-align: center;
color: #9CA3AF;
}

.nav-active {
  color: $secondaryColor;
}

@media only screen and (max-width: 768px) {

}
</style>