<template>
  <div class="footer">
    <b-container :fluid="($route.path === '/reksadana') || ($route.path === '/obligasi')" class="footer-container">
      <div class="row mt-4 footer-up-row">
        <div class="col-lg-3 col-md-6">
          <img class="cgs-logo mt-n4" src="@/assets/img/CGSI-iTrade-Fund-LOGO_WHITE.png" alt="cgs-logo" />

          <p class="mt-4 mb-2">Berizin & Diawasi Oleh Otoritas Jasa Keuangan</p>

          <!-- <img
            class="ojk-logo"
            src="@/assets/img/footer/ojk-logo.webp"
            alt="cgs-logo"
          /> -->

        </div>
        <div class="col-lg-2 col-md-6 company">
          <!--h3 class="mb-4">COMPANY</h3-->
          <h4 class="my-2">
            <b-link class="footer-link" :href="`${frontendLink}/tentang-kami`">
              Tentang Kami
            </b-link>
          </h4>
          <h4 class="my-2">
            <b-link class="footer-link" :href="`${frontendLink}/karir`">
              Karir
            </b-link>
          </h4>
          <h4 class="my-2">
            <b-link class="footer-link" :href="`${frontendLink}/articles`">
              Blog
            </b-link>
          </h4>
          <h4 class="my-2">
            <b-link class="footer-link" :href="`${frontendLink}/hubungi-kami`">
              Kontak
            </b-link>
          </h4>
        </div>
        <div class="col-lg-2 col-md-6 support">
          <!--h3 class="mb-4">SUPPORT</h3-->
          <!-- <h4 class="my-2">
              <b-link class="footer-link" to="/panduan">
                  Panduan
              </b-link>
          </h4>                -->
          <h4 class="my-2">
            <b-link class="footer-link" :href="`${frontendLink}/faq`">
              FAQ
            </b-link>
          </h4>
          <h4 class="my-2">
            <b-link class="footer-link" :href="`${frontendLink}/syarat-dan-ketentuan`">
              Syarat & Ketentuan
            </b-link>
          </h4>
        </div>
        <!--div class="col-lg-5 col-md-6 newsletter">
          <h1>Newsletter</h1>
          <p>
            Segarkan wawasan investasi Anda setiap harinya dengan berita-berita
            financial dari newsletter kami.
          </p>
          <b-row class="p-3">
            <div>
              <b-form class="email-form" inline @submit.prevent="handleSubmit">
                <label class="sr-only" for="email">Masukkan Email Anda</label>
                <b-form-input
                  id="email"
                  v-model="email"
                  required
                  class="mb-4 mr-sm-2 mb-sm-0"
                  placeholder="Masukkan Email Anda"
                ></b-form-input>

                <b-button
                  @click="handleSubmit()"
                  :disabled="loading"
                  class="btn-subscribe py-2"
                >
                  <div v-if="loading">
                    <div class="spinner-border spinner-border-sm"></div>
                    Loading
                  </div>
                  <div v-else>Subscribe</div>
                </b-button>
              </b-form>
            </div>
          </b-row>
        </div-->
      </div>
      <div>
        <b-jumbotron>
          <hr />
        </b-jumbotron>
      </div>
      <div class="row footer-down-row">
        <div class="col-lg-10 col-md-6">
          <div class="d-md-flex pb-5 pb-md-0">
            <p class="footerCompany">
              &#64; {{ new Date().getFullYear() }} PT CGS International Sekuritas Indonesia <span class="d-none d-md-inline">&nbsp; | &nbsp; {{ " " }}</span>
            </p>
            <div>
              <p class="footerCompany">
                No. Izin OJK: <a class="link" target="_blank" href="https://reksadana.ojk.go.id/Public/APERDPublic.aspx?id=YU069">S-231/PM.02/2024</a>
              </p>
              <p class="footerCompany">
                No. Izin KOMINFO: 000964.06/DJAI.PSE/06/2023
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6">
          <div class="row footer-down-row">
            <div class="mx-2">
              <b-link href="https://www.instagram.com/CGS CIMBid/"><img class="icons"
                  src="@/assets/icons/ic-instagram.svg" alt="instagram-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://www.facebook.com/CGS CIMBindonesia/"><img class="icons"
                  src="@/assets/icons/ic-facebook.svg" alt="instagram-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://twitter.com/CGS CIMBid/"><img class="icons" src="@/assets/icons/ic-x.svg"
                  alt="x-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://www.youtube.com/channel/UCkXWOYFkKqCY-H9AFRp_pug"><img class="icons"
                  src="@/assets/icons/icons8-youtube.svg" alt="youtube-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://www.tiktok.com/@cgsi_id?lang=en"><img class="icons" src="@/assets/icons/ic-tiktok.svg"
                  alt="tiktok-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://www.linkedin.com/company/cgsiid"><img class="icons" src="@/assets/icons/ic-linkedin.svg"
                  alt="linkedin-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://t.me/s/cgsiidretailresearch"><img class="icons" src="@/assets/icons/ic-telegram.svg"
                  alt="telegram-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://discord.com/invite/fdWdxmdQj2"><img class="icons" src="@/assets/icons/ic-discord.svg"
                  alt="discord-icon" /></b-link>
            </div>
            <div class="mx-2">
              <b-link href="https://spoti.fi/2AMBtLk"><img class="icons" src="@/assets/icons/icons8-spotify.svg"
                  alt="spotify-icon" /></b-link>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-subscribe">
        <b-modal id="modal-success-subscribe" v-model="show" size="md" class="modal-success-subscribe" centered
          hide-footer>
          <div class="d-block text-center">
            <img src="@/assets/img/subscribe/success-subscribe.svg" alt="success-subscribe" />
            <h1 class="p-4 my-2 text-dark">
              Hore! <br />
              Sukses Berlangganan
            </h1>
            <p>
              Segarkan wawasan investasi<br />dengan berita-berita dari kami.
            </p>
          </div>
        </b-modal>

        <b-modal id="modal-failed-subscribe" v-model="subscribedShow" size="md" class="modal-failed-subscribe" centered
          hide-footer>
          <div class="d-block text-center">
            <img src="@/assets/img/subscribe/subscribed.svg" alt="subscribed" />
            <h1 class="p-4 my-2 text-dark">Anda sudah berlangganan</h1>
            <p>Kami mengirimkan berita seputar investasi ke email Anda.</p>
          </div>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      show: false,
      subscribedShow: false,
      email: "",
      loading: false,
      processing: false,
      frontendLink: process.env.VUE_APP_ROOT_FRONTEND,
    };
  },
  methods: {
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;

        let params = {
          email: this.email,
        };

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios
          .post(
            "https://investamart-ka9.mailtrgt.com/form/611f705ddcb8710006b22905",
            params,
            axiosConfig
          )
          .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            this.show = true;

            this.loading = !false;
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.email = "";
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.subscribedShow = true;
            this.email = "";
            this.processing = false;
          });
      }
    },
  },
  props: {
    fluid: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.footerCompany {
  font-weight: bold;

  .link {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: normal;
    }
  }
}

.icons {
  width: 16px;
  height: 16px;
  color: white;
}

.footer {
  // background: white;
  background-image: url(../../../assets/img/footer/footerbackground.png);
}

.footer-container {
  padding-top: 40px;
  padding-bottom: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  // color: #898d8d;
  color: white;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: $tertiaryColor;
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // color: #898d8d;
  color: white;
}

.modal-subscribe {
  z-index: 99999;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }
}

.cgs-logo {
  width: 190px; //140 before
}

.form-control {
  width: 295px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.jumbotron {
  padding: 0;
  background-color: white;
  opacity: 0.3;
}

.footer-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // color: #898d8d;
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .footer-up-row {
    text-align: center;
  }

  .company {
    margin-top: 20px;
  }

  .support {
    margin-top: 20px;
  }

  .newsletter {
    margin-top: 20px;
  }

  .btn-subscribe {
    margin: auto;
  }

  .email-form {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-down-row {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
